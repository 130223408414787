import { template as template_24705d337f6546e5815db2e38ce1c9f6 } from "@ember/template-compiler";
const FKText = template_24705d337f6546e5815db2e38ce1c9f6(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
