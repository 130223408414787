import { template as template_1d6be5f6de464bd59471482db4af23d8 } from "@ember/template-compiler";
const FKControlMenuContainer = template_1d6be5f6de464bd59471482db4af23d8(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
