import { template as template_a84283526ebe489faf60e69a498cc272 } from "@ember/template-compiler";
const FKLabel = template_a84283526ebe489faf60e69a498cc272(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
